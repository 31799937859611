/*** Variables ***/
$primary: #000;
$secondary: #fff;
$gray: #747676;
$gray-1: #f6f6f6;
$gray-3: #d8dada;
$gray-4: #c9c9ca;
$gray-5: #cccccc;
$gray-6: #d5d5d5;
$gray-7: #d8d8d8;
$gray-8: #a1a1a1;
$gray-9: #646464;
$gray-10: #575858;
$gray-11: #eeeeee;
$gray-12: #6F7171;
$black-1: #212121;
$black-2: #111111;
$black-3:#070707;
$accent: #d52b1e;
$error: #ed7000;
$error-1: #FFBC3D;
$orange: #ff9100;
$info: #0088ce;
$green: #009900;
$green-1: #00B845;
$grey-banner: #333333;
$color-checkbox-disabled: #dadcdc;
$cyan-blue: #d6eefb;
$amber: #fff4e0;
$cream: #FFF9DE;
$lite-violet: #f6d6f6;
$pale-blue: #E3F2FD;


$font-family-bold: 'Verizon NHG DS'; // bold. should be used for all headings and sub headings
$font-family-regular: 'Verizon NHG TX'; // regular.
$font-family-etx: 'Verizon NHG eTX';
$font-family-eds: 'Verizon NHG eDS';

/*** Functions ***/
@function to-rem($px) {
  @return $px * 0.0625rem;
}

/* Dynamic Fonts till 100px */
@for $size from 1 to 100 {
  .font-#{$size} {
    font-size: $size * 1px !important;
  }
  .lh-#{$size} {
    line-height: $size !important;
  }
}

/*** Mixins ***/
//(xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)
@mixin breakpoint($point) {
  @if $point == sm {
    @media (max-width: 575px) {
      @content;
    }
  } @else if $point == md {
    @media (min-width: 576px) and (max-width: 991px) {
      @content;
    }
  } @else if $point == lg {
    @media (min-width: 992px) and (max-width: 1199px) {
      @content;
    }
  } @else if $point == xl {
    @media (min-width: 1200px) {
      @content;
    }
  }
}

@mixin breakpoint-upto($point) {
  @if $point == sm {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $point == md {
    @media (max-width: 991px) {
      @content;
    }
  } @else if $point == lg {
    @media (max-width: 1199px) {
      @content;
    }
  } @else if $point == xl {
    @media (min-width: 1200px) {
      @content;
    }
  }
}

@mixin breakpoint-from($point) {
  @if $point == md {
    @media (min-width: 768px) {
      @content;
    }
  }
}