/* You can add global styles to this file, and also import other style files */
@import url('./../node_modules/mbtcommonstatic/mbtassets/assets/vzrf-extend/stylesheets/app/app.css');
@import url('./../node_modules/mbtcommonstatic/mbtassets/assets/vzrf-3/@vzrf/core/index.css');

@import url('../node_modules/primeicons/primeicons.css');
@import url('../node_modules/primeng/resources/primeng.min.css');
@import url('../node_modules/primeng/resources/themes/lara-light-blue/theme.css');

@import './assets/styles/utils';

.deviceInfoSidebar{
    .p-sidebar{
        width: 50rem !important;
    }
}

.p-dialog .p-dialog-header .p-dialog-title{
    font-weight: 700 !important;
}

.custom_grid_margin {
    margin-right: -15px !important;
    margin-left: -15px !important;

    .custom_col_padding {
        padding-right: 15px !important;
        padding-left: 15px !important;
    }

}

.pointer {
    cursor: pointer;
}
.actionLink,
.actionLink:hover {
    color: $primary;
    text-decoration: underline !important;
    cursor: pointer;
}

a, .Link, a:hover, .Link:hover {
    color: #000000;
    text-decoration: none;
}

.v3fontRegular {
    font-family: var(--edsRegular) !important;
    font-weight: var(--regular-weight) !important;
    letter-spacing: .5px;
}
.v3fontBold {
    font-family: var(--edsBold) !important;
}

.zero_margin {
    margin: 0px;
}

.borderInput {
    border: 1px solid $gray-3 !important;
    border-bottom: 1px solid $primary !important;
}

.widthInput {
    width: 100%;
}

.Form-input:focus::placeholder,
input:focus::-webkit-input-placeholder {
    color: transparent;
    opacity: 0 !important;
}

.radioGroup {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.radioGroup input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* On mouse-over, add a grey background color */
.radioGroup:hover input~.checkmark {
    background-color: #fff;
}

/* When the radio button is checked, add a blue background */
.radioGroup input:checked~.checkmark {
    background-color: #fff;
}

/* Show the indicator (dot/circle) when checked */
.radioGroup input:checked~.checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.radioGroup .checkmark:after {
    top: 6px;
    left: 6px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: #333;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 23px;
    width: 23px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #333;
}

/* On mouse-over, add a grey background color */
.radioGroup:hover input~.checkmark {
    background-color: #fff;
}

/* When the radio button is checked, add a blue background */
.radioGroup input:checked~.checkmark {
    background-color: #fff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.radioGroup input:checked~.checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.radioGroup .checkmark:after {
    top: 6px;
    left: 6px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: #333;
}

.ui-button {
    background-color: white !important;
    top: 0px;
    left: -143px;
    border-color: white;
}

/* Calendar related styling override for primeng v14 */
.custom-form {
    .p-calendar {
        display: inline-grid;
        width: 100%;

        .p-button {
            position: absolute;
            top: 0px;
            left: 12px;
            color: black;
            @extend .ui-button;
            background: transparent !important;
            border-color: transparent;
            outline: none;
            border: 0;
            padding: 0.2em;
            height: 100%;
            display: inline-block;
            padding: 0;
            text-decoration: none !important;
            cursor: pointer;
            text-align: center;
            overflow: visible;
        }
    }

    .p-button-icon-only {
        .p-button-icon {
            position: absolute;
            top: 11px;
            // left: 0;
            // margin-top: -1.7em;
            // margin-left: 5em;
            // width: 1em;
            // height: 1em;
        }
    }

    .p-datepicker {

        &::before,
        &::after {
            content: "";
            display: table;
        }

        background: #fff;
        padding: 0.2em;
        min-width: 250px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);

        table {
            border: transparent;
            cursor: pointer;
            width: 100%;
            font-size: .9em;
            border-collapse: collapse;
            margin: 0 0 0.4em;
        }

        th {
            background: transparent;
            padding: 0.5em;
            text-align: center;
            font-weight: bold;
            border: 0;
        }

        td {
            border: 0;
            padding: 0;

            span:hover {
                background-color: #d8dada;
                border-radius: 100%;
            }
        }

        span,
        a {
            color: #000;
            display: block;
            padding: 0.5em;
            text-decoration: none;
            border: 0 none;
            text-align: center;
        }

        .p-datepicker-prev {
            left: 2.125em;
            position: absolute;
            // top: 0.5em;
            // width: 1.8em;
            // height: 1.8em;
            display: block;

            span {
                display: block;
                position: absolute;
                left: 50%;
                top: 50%;
                margin-top: -0.6em;
                margin-left: -0.5em;

                &::before {
                    color: #000;
                }
            }
        }

        .p-datepicker-next {
            right: 2.125em;
            position: absolute;
            // top: 0.5em;
            // width: 1.8em;
            // height: 1.8em;
            display: block;

            span {
                display: block;
                position: absolute;
                left: 50%;
                top: 50%;
                margin-top: -0.6em;
                margin-left: -0.5em;

                &::before {
                    color: #000;
                }

            }
        }

        .p-datepicker-title {
            font-family: BrandFontBold;
            font-weight: bold;
            font-size: 13px;
            margin: 0 2.3em;
            line-height: 1.8em;
            text-align: center;
            position: relative;
            top: 6px;
        }
    }

    .p-datepicker-today {
        background-color: #d8dada !important;
        border-radius: 100%;
    }

    .p-datepicker-group {

        border-left-width: 0;
        border-right-width: 0;
        border-top-width: 0;
        border-bottom-width: 0;

        .p-datepicker-header {
            position: relative;
            padding: 0.5em 0;

            &::before,
            &::after {
                content: "";
                display: table;
            }

            button {
                display: inline;
                background: #fff;
                border: none;
                // padding: 0;

                &:hover {
                    background: transparent;
                    border-color: transparent;
                    cursor: pointer;
                }

                .pi {
                    font-family: 'primeicons' !important;
                }
            }

            .p-datepicker-month {
                pointer-events: none;
                cursor: auto;
            }

            .p-datepicker-year {
                @extend .p-datepicker-month;
                margin-left: 0.25em;
            }

            .p-datepicker-title {
                button {
                    padding: 0 !important;
                }
            }
        }

        .p-link {
            color: #000000;
        }

        .p-disabled {
            color: #747676;
        }

        .p-highlight {
            color: #fff;
            background: #000;
            border-radius: 100%;
        }
    }

    .icon-input {
        position: relative;

        &.no-border>input {
            border: 0px;
        }

        &.border-error input {
            border: 1px solid #ed7000;
            border-bottom: 4px solid #ed7000;
        }

        &.disabled input {
            border: 1px solid #d8dada;
            background: #fff;
        }

        .border-botttom {
            input {
                border: 1px solid #d8dada;
                border-bottom: 1px solid #000;
                color: #000;
            }
        }

        &.disabled>i {
            color: #d8dada;
        }

        .border-black {
            input {
                border: 1px solid #000;
            }
        }

        &>i {
            position: absolute;
            left: 15px;
            top: 6px;
            font-size: 20px;
        }

        input {
            position: static;
            width: 100%;
            height: 44px;
            padding: 12px !important;
            background: var(--white-color) !important;
            border: 1px solid var(--color-cool-gray6) !important;
            border-radius: 4px;
            padding-left: 3.482rem !important;
            box-sizing: border-box;
            font-size: 14px;
            color: #000;
            background-repeat: no-repeat;
            background-position: right 0.875rem center;
            background-size: 0.9rem;
            cursor: pointer;

            &:focus {
                outline: none;
            }
        }
    }

    &.full-width {
        .icon-input {
            input {
                width: 100%;
            }
        }

        .p-calendar {
            width: 100%;

            .p-datepicker {
                min-width: 250px;
            }
        }
    }

    .inline-calendar {
        .p-calendar {
            border: 1px solid #000;
        }
    }

    label {
        color: #747676;
        font-size: 10px;
    }
}